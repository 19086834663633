
import { defineComponent, ref, computed } from "vue";
import { svg2imgSrc } from "@/utils/svgtool";
import { addresses } from "@/utils/addresses";

// mint
import NetworkGate from "@/components/NetworkGate.vue";
import { BigNumber, Transaction, utils } from "ethers";
import { ChainIdMap, displayAddress } from "@/utils/MetaMask";
import {
  useSVGTokenNetworkContext,
  getProvider,
  getSVGTokenContract,
} from "@/utils/const";

//
import { parse } from "svg-parser";
import { useStore } from "vuex";
import { convSVG2Path, dumpConvertSVG } from "@/utils/svgtool";
import { compressPath } from "@/utils/pathUtils";
import router from "@/router";

const sleep = async (seconds: number) => {
  return await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export default defineComponent({
  components: {
    NetworkGate,
  },
  setup(props) {
    const file = ref();

    const svgData = ref("");
    const convedSVGData = ref("");

    const svgText = ref("");
    const convedSVGText = ref("");

    const pathData = ref<any>([]);
    const existData = computed(() => {
      return pathData.value.length > 0;
    });

    const isExecuting = ref(0); // 0:non-execute, 1:executing, 2:complete

    const store = useStore();
    const account = computed(() => store.state.account);
    const prices = ref<any>([]);
    const set_price = ref<string>("");

    const reset = () => {
      svgText.value = "";
      svgData.value = "";
      pathData.value = [];
      convedSVGText.value = "";
      convedSVGData.value = "";
    };
    const readSVGData = async () => {
      try {
        svgText.value = await file.value.text();
        svgData.value = svg2imgSrc(svgText.value);

        pathData.value = convSVG2Path(svgText.value, true);
        convedSVGText.value = dumpConvertSVG(pathData.value);
        convedSVGData.value = svg2imgSrc(convedSVGText.value);
      } catch (e) {
        reset();
        console.log(e);
        alert("Sorry, this svg is not supported.");
      }
    };

    const uploadFile = (e: any) => {
      if (e.target.files && e.target.files.length > 0) {
        file.value = e.target.files[0];
        readSVGData();
      }
    };
    const dragFile = (e: any) => {
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        file.value = e.dataTransfer.files[0];
        readSVGData();
      }
    };

    const network = "mumbai";
    const tokenAddress = addresses.svgtoken[network];

    const chainId = ChainIdMap[network];

    const { networkContext } = useSVGTokenNetworkContext(chainId, tokenAddress);
    store.state.networkContext = networkContext;

    const alchemyKey = process.env.VUE_APP_ALCHEMY_API_KEY;
    const provider = getProvider(network, alchemyKey);
    const tokenContract = getSVGTokenContract(tokenAddress, provider);

    const nextToken = ref(0);

    const polling = async (tx: any) => {
      const receipt = await tx.wait();
      if (receipt.status == 1) {
        // success transaction
        const events = receipt.events.filter(
          (event: any) => event.event === "Transfer"
        );

        const returnValue = events[0].args[2].toNumber(); // 返り値 Transfer(from, to, tokenId)
        return returnValue;
      } else {
        console.log("receipt", receipt);
        alert("Sorry, transaction failed.");
      }
    };

    const mint = async () => {
      if (networkContext.value == null) {
        return;
      }
      const { contract } = networkContext.value;
      isExecuting.value = 1;

      const ret = {
        paths: [] as string[],
        fills: [] as string[],
        strokes: [] as string[],
        matrixes: [] as string[],
      };
      pathData.value.map((a: any) => {
        ret.paths.push(
          "0x" + Buffer.from(compressPath(a.path, 1024)).toString("hex")
        );
        ret.fills.push(a.fill || "");
        ret.strokes.push(a.strokeW);
        ret.matrixes.push(a.matrix || "");
      });

      try {
        console.log(ret);
        const price = utils.parseEther(set_price.value);
        console.log(price);
        const tx = await contract.mintToSell(ret, price);
        console.log("mint:tx");
        const result = await tx.wait();
        const tokenId = await polling(tx);
        console.log("mint:tokenId", tokenId);
        console.log("mint:gasUsed", result.gasUsed.toNumber());

        reset();
        isExecuting.value = 0;
        router.push({
          name: "item",
          params: { token_id: tokenId },
        });
      } catch (e) {
        // ウォレットで拒否した場合
        if (String(e).indexOf("ACTION_REJECTED") == -1) {
          console.error(e);
          alert("Sorry, transaction is failured.");
        }
        isExecuting.value = 0;
      }
    };
    return {
      uploadFile,
      dragFile,

      svgData,
      convedSVGData,

      svgText,
      convedSVGText,

      // mint
      mint,
      chainId,

      existData,
      isExecuting,

      prices,
      set_price,
    };
  },
});
