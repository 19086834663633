
import { defineComponent, ref, computed, PropType, toRefs, watch } from "vue";
import { utils } from "ethers";
import { useStore } from "vuex";
import { Token721p2p } from "@/models/token";

export default defineComponent({
  props: {
    token_obj: {
      type: Object as PropType<Token721p2p>,
      required: true,
    },
  },
  emits: ["purchased", "priceUpdated"],
  setup(props, context) {
    const isExecuting = ref(0); // 0:non-execute, 1:executing, 2:complete
    const store = useStore();
    const newPrice = ref<string>("0");
    const { token_obj } = toRefs(props);
    watch(token_obj, () => {
      console.log("TokenActions: detected token_obj change.");
      newPrice.value = token_obj.value?.price;
    });
    const account = computed(() => store.state.account);

    const polling = async (tx: any) => {
      const receipt = await tx.wait();
      if (receipt.status == 1) {
        return;
      } else {
        console.log("receipt", receipt);
        alert("Sorry, transaction failed.");
      }
    };

    const setPrice = async (id: number) => {
      if (store.state.networkContext == null) {
        return;
      }
      isExecuting.value = 1; // execute

      try {
        console.log(id);
        const tokenid = id;
        console.log("newPrice : ", newPrice.value, "tokenid : ", tokenid);
        const price = utils.parseEther(newPrice.value);
        console.log(tokenid, newPrice);

        console.log("price : ", price);

        const { contract } = store.state.networkContext;
        const tx = await contract.setPriceOf(tokenid, price);
        const result = await tx.wait();
        await polling(tx);
        isExecuting.value = 0; // non-execute
        context.emit("priceUpdated");
      } catch (e) {
        console.error(e);
        alert("Sorry, setPrice failed with:" + e);
        isExecuting.value = 0; // non-execute
      }
    };
    const isChangePrice = computed(() => {
      return token_obj.value.price !== newPrice.value
    });
    
    const purchase = async (id: number) => {
      if (store.state.networkContext == null) {
        return;
      }
      isExecuting.value = 1; // execute
      const { contract } = store.state.networkContext;
      try {
        //        console.log(id,(token_.value));
        const price = await contract.getPriceOf(id);
        const owner = await contract.ownerOf(id);
        const tx = await contract.purchase(id, account.value, owner, {
          value: price,
        });
        const result = await tx.wait();
        await polling(tx);
        isExecuting.value = 0; // non-execute
        context.emit("purchased");
      } catch (e) {
        console.error(e);
        isExecuting.value = 0; // non-execute
        alert("Sorry, purchase failed with:" + e);
        isExecuting.value = 0; // non-execute
      }
    };

    return {
      setPrice,
      purchase,
      newPrice,
      isExecuting,
      isChangePrice,
    };
  },
});
