
import { defineComponent } from "vue";
import Languages from "@/components/Languages.vue";
import NetworkGate from "@/components/NetworkGate.vue";
import { ChainIdMap } from "@/utils/MetaMask";

export default defineComponent({
  components: {
    Languages,
    NetworkGate,
  },

  setup(props) {
    const network = "mumbai";
    const chainId = ChainIdMap[network];

    return {
      chainId,
    };
  },
});
