<template>
  <footer class="m-4 rounded-lg bg-white shadow dark:bg-gray-900">
    <div class="mx-auto w-full max-w-screen-xl p-4 md:py-8">
      <div class="sm:flex sm:items-center sm:justify-between">
        <a
          href="https://www.singularitysociety.org"
          class="mb-4 flex items-center sm:mb-0"
        >
          <img
            src="@/assets/SingularitySociety-LogoMark-Color.svg"
            class="mr-3 h-8"
            alt="Flowbite Logo"
          />
          <span
            class="self-center whitespace-nowrap text-2xl font-semibold dark:text-white"
            >Singularity Society</span
          >
        </a>
        <ul
          class="mb-6 flex flex-wrap items-center text-sm font-medium text-gray-500 dark:text-gray-400 sm:mb-0"
        >
          <li>
            <a
              href="https://www.singularitysociety.org/join"
              class="hover:underline"
              >Contact</a
            >
          </li>
        </ul>
      </div>
      <hr
        class="my-6 border-gray-200 dark:border-gray-700 sm:mx-auto lg:my-8"
      />
      <span
        class="block text-sm text-gray-500 dark:text-gray-400 sm:text-center"
        >© 2023
        <a href="https://www.singularitysociety.org" class="hover:underline"
          >Singularity Society</a
        >. All Rights Reserved.</span
      >
    </div>
  </footer>
</template>
