
import { defineComponent, ref, computed, watch } from "vue";
import { svg2imgSrc } from "@/utils/svgtool";
import { BigNumber, utils } from "ethers";

// mint
import NetworkGate from "@/components/NetworkGate.vue";
import { ChainIdMap, displayAddress } from "@/utils/MetaMask";
import {
  useSVGTokenNetworkContext,
  getProvider,
  getSVGTokenContract,
} from "@/utils/const";

import { addresses } from "@/utils/addresses";
import TokenActions from "@/components/TokenActions.vue";

//
import { parse } from "svg-parser";
import { convSVG2Path, dumpConvertSVG } from "@/utils/svgtool";
import { compressPath } from "@/utils/pathUtils";

import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Token721p2p } from "@/models/token";

const sleep = async (seconds: number) => {
  return await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export default defineComponent({
  components: {
    NetworkGate,
    TokenActions,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const account = computed(() => store.state.account);

    const notFound = ref(false);
    const pathData = ref<any>([]);
    const existData = computed(() => {
      return pathData.value.length > 0;
    });

    const token_id = Number(route.params.token_id); //get from url parameter
    const token_obj = ref<Token721p2p>({
      data: { name: "", image: "" },
      price: 0,
      isOwner: false,
      token_id,
    });

    const reset = () => {
      pathData.value = [];
    };


    const network = "mumbai";
    const tokenAddress = addresses.svgtoken[network];

    const chainId = ChainIdMap[network];

    const { networkContext } = useSVGTokenNetworkContext(chainId, tokenAddress);
    store.state.networkContext = networkContext;

    const alchemyKey = process.env.VUE_APP_ALCHEMY_API_KEY;
    const provider = getProvider(network, alchemyKey);
    const tokenContract = getSVGTokenContract(tokenAddress, provider);
    const onid = tokenContract.on("Transfer", async (_, _to: BigNumber, _tokenId: BigNumber) => {
      const id = _tokenId.toNumber();
      if (token_id === id) {
        const isOwner = account.value ? 
        utils.getAddress(account.value) == utils.getAddress(_to.toString()) : false; 
        token_obj.value = {
          ...token_obj.value,
          isOwner,
          price: 0,
        }
        console.log("transfer update done");
      }
    });
    console.log(onid);
    const onid2 = tokenContract.on("SetPrice", async (_tokenId: BigNumber, _price: BigNumber) => {
      const id = _tokenId.toNumber();
      const price = Number(utils.formatEther(_price));
      
      if (token_id === id) {
        token_obj.value = {
          ...token_obj.value,
          price,
        }
      }
      console.log("price update done");
    });
    console.log(onid2);

    const nextToken = ref(0);

    watch(() => store.state.account, () => {
      updateToken();
    });
    
    const updateToken = async () => {
      console.log("1. updateToken was called.");

      console.log("requewt owner from contrcat");
      const strage_key = tokenAddress + "_" + token_id;
      const data_str = localStorage.getItem(strage_key);
      
      const data = await (async () => {
        if (data_str && data_str != "undefined") {
          console.log("load data from localstrage");
          return JSON.parse(data_str);
        } else {
          console.log("no localstrage request data");
          try {
            const ret = await tokenContract.tokenURI(token_id);
            const tmp = JSON.parse(atob(ret.split(",")[1]));
            localStorage.setItem(strage_key, JSON.stringify(tmp));
            return tmp;
          } catch (e) {
            notFound.value = true;
          }
        }
      })();
      if (notFound.value) {
        return;
      }
      token_obj.value = {
        ...token_obj.value,
        price: -1,
        data,
      }

      const [owner, price_big] = await Promise.all([
        tokenContract.ownerOf(token_id),
        tokenContract.getPriceOf(token_id),
      ]);
      const price = utils.formatEther(price_big);
      const isOwner = account.value ? 
        utils.getAddress(account.value) == utils.getAddress(owner) : false; 

      token_obj.value = {
        ...token_obj.value,
        price,
        isOwner,
      }
    };

    updateToken();

    const callUpdateToken = async () => {
      console.log("***###*** purchased or priceUpdated event was fired");
      updateToken();
    };

    const pageUrl = location.href;
    
    return {
      chainId,
      token_obj,
      existData,
      callUpdateToken,

      pageUrl,
      notFound,
    };
  },
});
